import React from 'react';
import ContentsMain from 'containers/Contents/CreateContents/ContentsMain/ContentsMain';
const ContentsCreatePage = () => {
  return (
    <>
      <ContentsMain />
    </>
  );
};

export default ContentsCreatePage;
