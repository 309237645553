import ContentsMain from 'containers/Contents/ContentsList/ContentsMain/ContentsMain';
import React from 'react';

const ContentsListPage = () => {
  return (
    <>
      <ContentsMain />
    </>
  );
};

export default ContentsListPage;
