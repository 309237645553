import MyAlbumMain from "containers/MyAlbum/MyAlbumMain";

function MyAlbumPage() {
  return (
    <div>
      <MyAlbumMain />
    </div>
  );
}
export default MyAlbumPage;
