import MyGardenDandelionDetail from 'containers/MyGarden/MyGardenDandelionDetail';
import React from 'react';

const MyDandelDetailPage = () => {
  return (
    <div>
      <MyGardenDandelionDetail />
    </div>
  );
};

export default MyDandelDetailPage;
