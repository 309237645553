import PlantEvent from 'containers/MyGarden/PlantEvent';
import React from 'react';

const MyDandelionPlant = () => {
  return (
    <div>
      <PlantEvent />
    </div>
  );
};

export default MyDandelionPlant;
