import MyGardenMain from "containers/MyGarden/MyGardenMain";
import React from "react";

function MyGardenPage() {
  return (
    <div>
      <MyGardenMain />
    </div>
  );
}

export default MyGardenPage;
