import React from 'react';

function DesktopPage () {
  
  return (
  <section>
    <iframe style={{height: "800px", width: "450px"}} src="/">
      
    </iframe>
  </section>

  
  
)};

export default DesktopPage;
